
import {useRouter} from 'vue-router';
import {defineComponent, PropType} from 'vue';
import {IBienBanResponseBase, IHopDongResponseBase, IPhieuNhapKhoResponseBase} from '@/core/interfaces/ApiResponses';
import {useButtonCustom} from '@/core/hooks/use-button-custom';
import {useEnv} from "@/core/hooks/useEnv";
import {useLoading} from '@/core/hooks/use-loading';
import {useRouterCustom} from '@/core/hooks/use-router-custom';
import {useDateTime} from '@/core/hooks/use-date-time-hook';
import {BienBanKiemNghiemService} from '@/core/services/BienBanKiemNghiem.service';
import {HopDongService} from '@/core/services/HopDong.service';
import {formatCurrencyAndQuantity, getErrorMsg, swalNotification} from '@/core/helpers/utils';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue'
import ConfirmHoanThanh
  from "@/views/crafted/pages/bien-ban-giai-the/confirm-hoan-thanh-bien-ban-modal/ConfirmHoanThanh.vue";
import BienBanKiemNghiemPrintModal
  from '@/components/shared/bien-ban-kiem-nghiem-print-modal/BienBanKiemNghiemPrintModal.vue';
import ConfirmationDialog from '@/components/confirmation-dialog/ConfirmationDialog.vue';
import {PhieuNhapKhoService} from '@/core/services/PhieuNhapKho.service';
import usePhieuNhap from '@/core/hooks/usePhieuNhap';

export default defineComponent({
  name: 'phieu-nhap-kho-table-v2',

  emits: ['on-detail-button-click', 'refresh'],

  components: {
    ConfirmationDialog,
    BienBanKiemNghiemPrintModal,
    ButtonCustom,
    ConfirmHoanThanh,
  },

  setup() {
    const {ButtonsType, ButtonTypeColors} = useButtonCustom();

    const {push} = useRouterCustom();

    const {push: pushRouter} = useRouter();

    const {formatDisplayingDate} = useDateTime();

    const {showHoanThanhPhieuNhap} = useEnv();

    const {startLoading, endLoading} = useLoading();

    const {editable, goToEditPhieuNhap} = usePhieuNhap();

    return {
      ButtonsType, ButtonTypeColors,
      formatDisplayingDate,
      push, pushRouter,
      showHoanThanhPhieuNhap,
      startLoading, endLoading,
      editable, goToEditPhieuNhap,
    }
  },

  props: {
    items: {
      type: Array as PropType<Array<IPhieuNhapKhoResponseBase>>,
      default: () => ([]),
    },
    idKho: {
      type: Number as PropType<number>,
      required: true,
    },
    showDeleteButton: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    showEditButton: {
      type: Boolean as PropType<boolean>,
      default: false,
    }
  },

  computed: {
    actionButtonsWidth() {
      let defaultWidth = 100;

      if (this.showHoanThanhPhieuNhap) {
        defaultWidth += 50;
      }

      if (this.showDeleteButton) {
        defaultWidth += 50;
      }

      if (this.showEditButton) {
        defaultWidth += 50;
      }

      return defaultWidth;
    },
  },

  data() {
    return {
      deletingPhieuNhap: null as IPhieuNhapKhoResponseBase | null,
      selectingPhieuNhap: null as IPhieuNhapKhoResponseBase | null,
      selectingBienBan: null as IBienBanResponseBase | null,
      selectingHopDong: null as IHopDongResponseBase | null,
    };
  },

  methods: {
    formatCurrencyAndQuantity,
    openDetail(row: IPhieuNhapKhoResponseBase) {
      this.$emit('on-detail-button-click', {
        phieuNhapKhoData: row,
      })
    },
    async openBienBanPrintModal(phieuNhapKhoDetail: IPhieuNhapKhoResponseBase) {
      try {
        await this.startLoading();

        const {data: {data}} = await BienBanKiemNghiemService.get(phieuNhapKhoDetail.id_bien_ban_kiem_nghiem);

        this.selectingBienBan = data;

        if (this.selectingBienBan.id_hop_dong) {
          const {data: {data: hopDongResponse}} = await HopDongService.fetchContractDetail(this.selectingBienBan.id_hop_dong);

          this.selectingHopDong = hopDongResponse;
        }

        await this.endLoading();
      } catch (err) {
        await this.endLoading();

        await swalNotification(
            getErrorMsg(err, 'Có lỗi xảy ra khi lấy thông tin biên bản'),
            'error',
        );
      }
    },
    confirmHoanThanh() {
      this.selectingPhieuNhap = null;

      this.$emit('refresh');
    },
    closePrintModal() {
      this.hopDongDetail = null;

      this.selectingBienBan = null;
    },
    async deletePhieuNhap() {
      if (!this.deletingPhieuNhap) return;

      try {
        await PhieuNhapKhoService.delete(this.deletingPhieuNhap.id);

        this.deletingPhieuNhap = null;

        this.$emit('refresh');
      } catch (error) {
        this.deletingPhieuNhap = null;

        await swalNotification(
            getErrorMsg(error, 'Có lỗi xảy ra khi xoá phiếu nhập này'),
            'error',
        );
      }
    },
  },
});
