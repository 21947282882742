
import { defineComponent, PropType } from 'vue';
import { formatCurrencyAndQuantity } from '@/core/helpers/utils';
import { useRouterCustom } from '@/core/hooks/use-router-custom';
import { IVatTuTrongKhoResponseBase } from '@/core/interfaces/ApiResponses';
import { useButtonCustom } from '@/core/hooks/use-button-custom';

import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import WordBreakColumn from '@/components/shared/work-break-column/WordBreakColumn.vue';
import useVatTu from '@/core/hooks/use-vat-tu';

export default defineComponent({
  name: 'vat-tu-kho-table',

  emits: ['on-delete'],

  components: { ButtonCustom, WordBreakColumn },

  setup() {
    const { push } = useRouterCustom();

    const { ButtonTypeColors, ButtonsType } = useButtonCustom();

    const { isTextDangerColumn, isValidLabel } = useVatTu();

    return {
      push,
      ButtonTypeColors, ButtonsType,
      isTextDangerColumn, isValidLabel,
    };
  },

  props: {
    emptyText: {
      type: String,
      default: 'Chưa có vật tư trong kho',
    },
    isDeleteButtonDisplay: {
      type: Boolean,
      default: true,
    },
    vatTuItems: {
      type: Array as PropType<Array<IVatTuTrongKhoResponseBase>>,
      default: () => ([]),
    },
  },

  data() {
    return {
      items: this.vatTuItems as IVatTuTrongKhoResponseBase[],
    }
  },

  watch: {
		vatTuItems(value) {
			this.items = value;
		},
	},

  computed: {
    widthComputed() {
      if (!this.isDeleteButtonDisplay) {
        return 50;
      }

      return 100;
    },
  },

  methods: {
    formatCurrencyAndQuantity,

    donGiaDisplay(index: number) {
      return formatCurrencyAndQuantity(this.items[index].pivot.don_gia);
    },

    thanhTienDisplay(index: number) {
      return formatCurrencyAndQuantity(
        this.items[index].pivot.don_gia * this.items[index].pivot.so_luong,
        false,
      );
    },

    onDeleteVatTuClicked(vatTu: IVatTuTrongKhoResponseBase) {
      this.$emit('on-delete', {
        vatTu,
      });
    }
  }
})
