import { apiServiceInstance } from '../helpers/utils';
import { ApiResponse, IBienBanResponseBase } from '../interfaces/ApiResponses';

export interface IBienBanKiemNghiemWithPhieuLePayload {
  id_bien_ban?: number;
  id_kho: number;
  ngay_bien_ban: string;
  ten_giam_doc: string;
  thanh_phan_hoi_dong: unknown;
  y_kien: string;
  matk_co: string;
  mact_co?: string;
  so_hoa_don?: string;
  tentk_co: string;
  vat_tu_items: {
    id: number,
    so_luong: number,
    don_gia: number,
    ket_qua_kiem_nghiem: number,
    phuong_thuc_kiem_nghiem: string,
  }[],
}

export class BienBanKiemNghiemService {
  static createFromHopDong({
    thanh_phan_hoi_dong,
    ten_giam_doc,
    ngay_bien_ban,
    y_kien,
    loai_hop_dong,
    id_kho,
    id_hop_dong,
    vat_tu_items,
  }: {
    thanh_phan_hoi_dong: unknown,
    ngay_bien_ban: string,
    ten_giam_doc: string,
    y_kien: string,
    loai_hop_dong: number,
    id_kho: number,
    id_hop_dong: number,
    vat_tu_items: {
      id: number,
      so_luong: number,
      don_gia: number,
      ket_qua_kiem_nghiem: number,
      phuong_thuc_kiem_nghiem: string,
    }[],
  }): Promise<{
    data: ApiResponse<IBienBanResponseBase>
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";

    const bodyFormData = new FormData();

    bodyFormData.append('bienBanKiemNghiem[ngay_bien_ban]', ngay_bien_ban);

    bodyFormData.append('bienBanKiemNghiem[thanh_phan_hoi_dong]', JSON.stringify(thanh_phan_hoi_dong));

    bodyFormData.append('bienBanKiemNghiem[y_kien]', y_kien);

    bodyFormData.append('bienBanKiemNghiem[ten_giam_doc]', ten_giam_doc);

    bodyFormData.append('bienBanKiemNghiem[id_kho]', id_kho.toString());

    vat_tu_items.forEach(vatTu => {
      if (vatTu.so_luong > 0) {
        if (loai_hop_dong === 2) {
          bodyFormData.append(`bbVatTu[${vatTu.id}][don_gia]`, vatTu.don_gia.toString());
        }

        bodyFormData.append(`bbVatTu[${vatTu.id}][so_luong]`, vatTu.so_luong.toString());

        bodyFormData.append(`bbVatTu[${vatTu.id}][ket_qua_kiem_nghiem]`, vatTu.ket_qua_kiem_nghiem.toString());

        bodyFormData.append(`bbVatTu[${vatTu.id}][phuong_thuc_kiem_nghiem]`, vatTu.phuong_thuc_kiem_nghiem);
      }
    });

    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/bien-ban-kiem-nghiem/tao-moi/${id_hop_dong}`,
      bodyFormData
    );
  }

  static async createOrUpdateBienBanWithPhieuLe({
    ngay_bien_ban,
    id_kho,
    id_bien_ban,
    thanh_phan_hoi_dong,
    ten_giam_doc,
    vat_tu_items,
    y_kien,
    so_hoa_don,
    mact_co,
    matk_co,
    tentk_co,
  }: IBienBanKiemNghiemWithPhieuLePayload): Promise<{
    data: ApiResponse<IBienBanResponseBase>
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
      ] = "multipart/form-data";
    const bodyFormData = new FormData();

    const isUpdating = !!id_bien_ban;

    bodyFormData.append('bienBanKiemNghiem[ngay_bien_ban]', ngay_bien_ban);

    bodyFormData.append('bienBanKiemNghiem[id_kho]', id_kho.toString());

    bodyFormData.append('bienBanKiemNghiem[thanh_phan_hoi_dong]', JSON.stringify(thanh_phan_hoi_dong));

    bodyFormData.append('bienBanKiemNghiem[y_kien]', y_kien);

    bodyFormData.append('bienBanKiemNghiem[ten_giam_doc]', ten_giam_doc);

    bodyFormData.append('bienBanKiemNghiem[matk_co]', matk_co);

    if (mact_co) {
      bodyFormData.append('bienBanKiemNghiem[mact_co]', mact_co);
    }

    if (so_hoa_don) {
      bodyFormData.append('bienBanKiemNghiem[so_hoa_don]', so_hoa_don);
    }

    bodyFormData.append('bienBanKiemNghiem[tentk_co]', tentk_co);

    vat_tu_items.forEach(vatTu => {
      if (vatTu.so_luong > 0) {
        bodyFormData.append(`bbVatTu[${vatTu.id}][don_gia]`, vatTu.don_gia.toString());

        bodyFormData.append(`bbVatTu[${vatTu.id}][so_luong]`, vatTu.so_luong.toString());

        bodyFormData.append(`bbVatTu[${vatTu.id}][ket_qua_kiem_nghiem]`, vatTu.ket_qua_kiem_nghiem.toString());

        bodyFormData.append(`bbVatTu[${vatTu.id}][phuong_thuc_kiem_nghiem]`, vatTu.phuong_thuc_kiem_nghiem);
      }
    });

    const URI = isUpdating
      ? `/api/v1/bien-ban-kiem-nghiem/cap-nhat/${id_bien_ban}`
      : '/api/v1/bien-ban-kiem-nghiem/tao-moi';

    return apiServiceInstance().vueInstance.axios.post(
      URI,
      bodyFormData,
    );
  }

  static get(id: number): Promise<{
    data: ApiResponse<IBienBanResponseBase>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/bien-ban-kiem-nghiem/chi-tiet/${id}`,
    );
  }
}
